import Global from '~icons/icons/GlobalMegaLogo.svg'
import GiorgioArmani from '~icons/icons/GiorgioArmaniMegaLogo.svg'
import GaPoldo from '~icons/icons/GaPoldoMegaLogo.svg'
import GaNeve from '~icons/icons/GaNeveMegaLogo.svg'
import ThatsSoArmani from '~icons/icons/ThatsSoArmaniMegaLogo.svg'
import GinzaTower from '~icons/icons/GinzaTowerMegaLogo.svg'
import EmporioArmani from '~icons/icons/EmporioArmaniMegaLogo.svg'
import Ea7 from '~icons/icons/Ea7MegaLogo.svg'
import ArmaniExchange from '~icons/icons/ArmaniExchangeMegaLogo.svg'
import ArmaniCasa from '~icons/icons/ArmaniCasaMegaLogo.svg'
import ArmaniFiori from '~icons/icons/ArmaniFioriMegaLogo.svg'
import ArmaniDolci from '~icons/icons/ArmaniDolciMegaLogo.svg'
import ArmaniBeauty from '~icons/icons/ArmaniBeautyMegaLogo.svg'
import ArmaniRestaurant from '~icons/icons/ArmaniRestaurantMegaLogo.svg'
import ArmaniHotelsResorts from '~icons/icons/ArmaniHotelsResortsMegaLogo.svg'
import ArmaniSilos from '~icons/icons/ArmaniSilosMegaLogo.svg'
import ArmaniClubs from '~icons/icons/ArmaniClubsMegaLogo.svg'
import EchiDalMondo from '~icons/icons/EchiDalMondoMegaLogo.svg'

export type MegaLogoType = keyof typeof megaLogoMap

export const megaLogoMap = {
  global: Global,
  'giorgio-armani': GiorgioArmani,
  'ga-poldo': GaPoldo,
  'ga-neve': GaNeve,
  'thats-so-armani': ThatsSoArmani,
  'ginza-tower': GinzaTower,
  'emporio-armani': EmporioArmani,
  ea7: Ea7,
  'armani-exchange': ArmaniExchange,
  'armani-casa': ArmaniCasa,
  'armani-fiori': ArmaniFiori,
  'armani-dolci': ArmaniDolci,
  'armani-beauty': ArmaniBeauty,
  'armani-restaurant': ArmaniRestaurant,
  'armani-hotels-resorts': ArmaniHotelsResorts,
  'armani-silos': ArmaniSilos,
  'armani-clubs': ArmaniClubs,
  'echi-dal-mondo': EchiDalMondo,
}
